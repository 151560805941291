/************* Services Item Styles
********************************/
.service__item {
  background-color: var(--bg__dark_color);
  /* background: rgba(23, 31, 56, 0.15); */
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(8.5px);
  border-radius: 10px;
  padding: 30px;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 1rem;
  transition: 0.3s;
}

.service__item:first-child {
  border-radius: 10px 0px 10px 10px;
}

.service__item:last-child {
  border-radius: 10px 10px 0px 10px;
}

.service__item:hover {
  background-color: var(--main__color);
  cursor: pointer;
  transform: translateX(-30px);
}

.service__item span i {
  font-size: 3rem;
  color: var(--main__color);
}

.service__item:hover i {
  color: var(--bg__dark_color);
}

.service__item h5,
.service__item span,
.service__item span i {
  background-color: transparent;
}
