/************* Footer Styles
********************************/

.footer {
  padding-top: 20px;
  padding-bottom: 10px;
  border-top: 1px solid var(--bg__dark_color);
}

@media only screen and (max-width: 768px) {
  .footer {
    padding-top: 0;
    border: none;
  }
}
.nav__menu {
  display: flex;
  align-items: center;
  column-gap: 2.5rem;
  margin-bottom: 20px;
}

.nav__menu a {
  color: var(--gry);
  font-weight: 500;
  font-size: 1rem;
  text-decoration: none;
  transition: 0.3s;
  cursor: pointer;
}

.nav__menu a:hover {
  color: var(--main__color);
}

.nav__menu .active {
  color: var(--main__color);
}

.social__list {
  text-align: right;
}
.social__list a {
  font-size: 1.55rem;
  font-weight: 400;
  color: var(--gry);
  margin-right: 7px;
}
.social__list a:last-child {
  margin-right: 0;
}

.social__list a:hover {
  color: var(--white);
}

/* scroll-to-top */
.scroll_arrow_btn a {
  width: 50px;
  height: 90px;
  line-height: 90px;
  background-color: transparent;
  /* background-color: var(--bg__dark_color); */
  /* border: 1px solid var(--white); */
  display: block;
  border-radius: 50px;
  text-align: center;
  margin-bottom: 10px;
}
.scroll_arrow_btn a i {
  font-size: 3rem;
  display: block;
  color: var(--white);
  transition: 0.3s;
  animation: animMoveTop 2s linear infinite;
}

.scroll_arrow_btn a:hover {
  border-color: var(--main__color);
}
.scroll_arrow_btn a:hover i {
  color: var(--main__color);
}

.footer__copyright {
  border-top: 1px solid var(--gry);
}
.footer__copyright p {
  font-size: 0.8rem;
  text-align: center;
  padding-top: 20px;
}

@media only screen and (max-width: 768px) {
  .footer__top {
    display: none;
  }
}

@keyframes animMoveTop {
  0% {
    transform: translateY(15px);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(10px);
  }
}
