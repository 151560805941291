.form__group input,
.form__group textarea {
  width: 100%;
  border: none;
  outline: none;
  background: var(--bg__dark_color);
  color: var(--white);
  padding: 15px 20px;
  border-radius: 5px;
  margin-bottom: 20px;
}

.form__group input::placeholder,
.form__group textarea::placeholder {
  font-size: 0.8rem;
  color: var(--gry);
}

.form button {
  width: 10rem;
  color: var(--main__color);
  border-color: var(--main__color);
  cursor: pointer;
  position: relative;
  overflow: hidden;
  z-index: 99;
  transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.form button:hover {
  color: var(--white);
}

.form button:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 5px;
  background-color: var(--main__color);
  z-index: -1;
  transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.form button:hover:after {
  width: 100%;
  height: 100%;
}
