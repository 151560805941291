/************* Single Project Details Styles
************************************************/
.project__heading {
  font-size: 40px;
  width: 700px;
  max-width: 100%;
  margin-bottom: 30px;
}

.project__heading h1 {
  font-size: 18px;
}

.project__details .feedback small {
  margin-top: -7px;
}

.main__preview__img {
  width: 100% !important;
}
.image__Preview {
  /* padding: 50px 0; */
}
.image__Preview img {
  width: 100% !important;
  height: auto;
}

.gallery__images {
  padding: 50px 0;
  user-select: none;
}
.gallery__images img {
  width: 100% !important;
}
/* .gallery__images span img {
   min-height: 200px; 
} */

.sub__title2 {
  color: var(--white) !important;
}
