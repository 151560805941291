/* ========== shapes ========= */
.l_shapes .one,
.l_shapes .two,
.l_shapes .three,
.l_shapes .four,
.l_shapes .five,
.l_shapes .six,
.l_shapes .seven,
.l_shapes .eight,
.l_shapes .nine,
.l_shapes .ten,
.l_shapes .eleven,
.l_shapes .twelve,
.l_shapes .thirteen,
.l_shapes .fourteen,
.l_shapes .fifteen,
.l_shapes .sixteen,
.l_shapes .seventeen,
.l_shapes .eighteen,
.l_shapes .nineteen,
.l_shapes .twenty {
  position: absolute;
}
.l_shapes .one {
  top: 15%;
  left: 7%;
}
.l_shapes .one .cls-1 {
  stroke: inherit;
  fill: none;
}
.l_shapes .one .svg {
  width: 10px;
  height: 10px;
  stroke: rgba(108, 99, 255, 0.3);
}
.l_shapes .two {
  bottom: 15%;
  left: 5%;
}
.l_shapes .two .cls-1 {
  stroke: none;
  fill: rgba(108, 99, 255, 0.3);
}
.l_shapes .two .svg {
  width: 95px;
  height: 95px;
}
.l_shapes .three {
  top: 13%;
  right: 5%;
}
.l_shapes .three .cls-1 {
  stroke: none;
  fill: rgba(108, 99, 255, 0.2);
}
.l_shapes .three .svg {
  width: 120px;
  height: 120px;
}
.l_shapes .four {
  top: 29%;
  left: 10%;
}
.l_shapes .four .cls-1 {
  stroke: inherit;
  fill: none;
}
.l_shapes .four .svg {
  width: 35px;
  height: 35px;
  stroke: rgba(169, 219, 90, 0.3);
}
.l_shapes .five {
  top: 50%;
  transform: translateY(-50%);
  left: 8%;
  transform: rotate(90deg);
}
.l_shapes .five .cls-1 {
  stroke: inherit;
  fill: none;
}
.l_shapes .five .svg {
  width: 35px;
  height: 35px;
  stroke: rgba(108, 99, 255, 0.3);
}
.l_shapes .six {
  bottom: 33%;
  left: 3%;
  transform: rotate(250deg);
}
.l_shapes .six .cls-1 {
  stroke: inherit;
  fill: none;
}
.l_shapes .six .svg {
  width: 30px;
  height: 30px;
  stroke: rgba(51, 51, 51, 0.3);
}
.l_shapes .seven {
  bottom: 4%;
  left: 10%;
}
.l_shapes .seven .cls-1 {
  stroke: inherit;
  fill: none;
}
.l_shapes .seven .svg {
  width: 35px;
  height: 35px;
  stroke: rgb(128, 39, 169, 31%);
}
.l_shapes .eight {
  bottom: 24%;
  left: 14%;
}
.l_shapes .eight .cls-1 {
  stroke: inherit;
  fill: none;
}
.l_shapes .eight .svg {
  width: 10px;
  height: 10px;
  stroke: rgba(169, 219, 90, 0.3);
}
.l_shapes .nine {
  top: 19%;
  left: 36%;
  transform: rotate(70deg);
}
.l_shapes .nine .cls-1 {
  stroke: inherit;
  fill: none;
}
.l_shapes .nine .svg {
  width: 30px;
  height: 30px;
  stroke: rgba(108, 99, 255, 0.3);
}
.l_shapes .ten {
  top: 25%;
  left: 50%;
  transform: rotate(180deg);
}
.l_shapes .ten .cls-1 {
  stroke: inherit;
  fill: none;
}
.l_shapes .ten .svg {
  width: 35px;
  height: 35px;
  stroke: rgba(169, 219, 90, 0.3);
}
.l_shapes .eleven {
  bottom: 50%;
  left: 48%;
}
.l_shapes .eleven .cls-1 {
  stroke: inherit;
  fill: none;
}
.l_shapes .eleven .svg {
  width: 10px;
  height: 10px;
  stroke: rgba(169, 219, 90, 0.3);
}
.l_shapes .twelve {
  bottom: 30%;
  left: 49%;
}
.l_shapes .twelve .cls-1 {
  stroke: inherit;
  fill: none;
}
.l_shapes .twelve .svg {
  width: 24px;
  height: 24px;
  stroke: rgb(128, 39, 169, 31%);
}
.l_shapes .thirteen {
  bottom: 26%;
  left: 39%;
}
.l_shapes .thirteen .cls-1 {
  stroke: inherit;
  fill: none;
}
.l_shapes .thirteen .svg {
  width: 18px;
  height: 18px;
  stroke: rgba(91, 215, 180, 0.3);
}
.l_shapes .fourteen {
  top: 14%;
  right: 13%;
}
.l_shapes .fourteen .cls-1 {
  stroke: inherit;
  fill: none;
}
.l_shapes .fourteen .svg {
  width: 10px;
  height: 10px;
  stroke: rgba(169, 219, 90, 0.3);
}
.l_shapes .fifteen {
  top: 32%;
  right: 2%;
}
.l_shapes .fifteen .cls-1 {
  stroke: inherit;
  fill: none;
}
.l_shapes .fifteen .svg {
  width: 35px;
  height: 35px;
  stroke: rgba(51, 51, 51, 0.3);
}
.l_shapes .sixteen {
  top: 50%;
  right: 3%;
}
.l_shapes .sixteen .cls-1 {
  stroke: inherit;
  fill: none;
}
.l_shapes .sixteen .svg {
  width: 10px;
  height: 10px;
  stroke: rgba(169, 219, 90, 0.3);
}
.l_shapes .seventeen {
  top: 59%;
  right: 3%;
}
.l_shapes .seventeen .cls-1 {
  stroke: inherit;
  fill: none;
}
.l_shapes .seventeen .svg {
  width: 35px;
  height: 35px;
  stroke: rgba(108, 99, 255, 0.3);
}
.l_shapes .eighteen {
  bottom: 19%;
  right: 10%;
}
.l_shapes .eighteen .cls-1 {
  stroke: inherit;
  fill: none;
}
.l_shapes .eighteen .svg {
  width: 30px;
  height: 30px;
  stroke: rgb(128, 39, 169, 31%);
}
.l_shapes .nineteen {
  bottom: 12%;
  right: 19%;
}
.l_shapes .nineteen .cls-1 {
  stroke: inherit;
  fill: none;
}
.l_shapes .nineteen .svg {
  width: 10px;
  height: 10px;
  stroke: rgba(169, 219, 90, 0.3);
}
.l_shapes .twenty {
  bottom: 12%;
  right: 3%;
}
.l_shapes .twenty .cls-1 {
  stroke: inherit;
  fill: none;
}
.l_shapes .twenty .svg {
  width: 35px;
  height: 35px;
  stroke: rgba(169, 219, 90, 0.3);
}

/* ========== icons shapes========= */
.icons__shapes span {
  position: absolute;
}

.icons__shapes .reactjs {
  top: 20%;
  right: 18%;
  animation: animCircle 15s infinite linear;
}
.icons__shapes .bootstrap {
  top: 5%;
  left: 55%;
  background: transparent !important;
  animation: animMoveTop 5s linear 0s infinite alternate;
}
.icons__shapes .sass {
  bottom: 30%;
  right: 50%;

  animation: animScale 5s linear 0s infinite alternate;
}
.icons__shapes .html5 {
  top: 20%;
  left: 35%;
  background: transparent !important;
  animation: animTranslate 2s linear 0s infinite alternate;
}
.icons__shapes .reactjs img {
  width: 70px;
  height: 70px;
}
.icons__shapes .bootstrap img {
  width: 40px;
  height: 50px;
}
.icons__shapes .sass img {
  width: 50px;
  height: 50px;
  background: transparent !important;
}
.icons__shapes .html5 img {
  width: 50px;
  height: 50px;
}

/* ========== shapes animation ========= */
.anim_circle {
  animation: animCircle 15s infinite linear;
}
@keyframes animCircle {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.anim_scale {
  animation: animScale 5s linear 0s infinite alternate;
}
@keyframes animScale {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(2);
  }
}
.anim_scale2 {
  animation: animScale2 5s linear 0s infinite alternate;
}
@keyframes animScale2 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.4);
  }
}
.anim_translate {
  animation: animTranslate 5s linear 0s infinite alternate;
}
@keyframes animTranslate {
  0% {
    transform: rotate(42deg) translateX(-5px);
  }
  100% {
    transform: rotate(42deg) translateX(5px);
  }
}

.anim_moveTop {
  animation: animMoveTop 5s linear 0s infinite alternate;
}
@keyframes animMoveTop {
  0% {
    transform: translateY(-30px);
  }
  100% {
    transform: translateY(30px);
  }
}
.anim_moveLeft {
  animation: animMoveLeft 5s linear 0s infinite alternate;
}
@keyframes animMoveLeft {
  0% {
    transform: translateX(-30px);
  }
  100% {
    transform: translateX(30px);
  }
}
.anim_extra {
  animation: animExtra 5s linear 0s infinite alternate;
}
@keyframes animExtra {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(180deg);
  }
}
.anim_right {
  animation: animRight 5s linear 0s infinite alternate;
}
@keyframes animRight {
  0% {
    transform: translateX(15px);
  }
  100% {
    transform: translateX(-15px);
  }
}
.anim_right2 {
  animation: animRight2 5s linear 0s infinite alternate;
}
@keyframes animRight2 {
  0% {
    transform: translateX(0px);
  }
  100% {
    transform: translateX(-30px);
  }
}
.anim_left2 {
  animation: animLeft2 5s linear 0s infinite alternate;
}
@keyframes animLeft2 {
  0% {
    transform: translateX(-30px);
  }
  100% {
    transform: translateX(0px);
  }
}

/* ========== Reaponsive ========== */
@media only screen and (max-width: 768px) {
  .icons__shapes .html5 {
    top: 22%;
    left: 60%;
  }
  .icons__shapes .reactjs {
    top: 5%;
    left: 7%;
  }
  .icons__shapes .sass {
    bottom: 8%;
    right: 28%;
  }
}
