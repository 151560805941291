/***************************
***** swiper Slider *******
**************************/
.swiper-wrapper {
  width: 100%;
  padding-bottom: 30px;
  margin-bottom: 30px;
}

.swiper-pagination {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.swiper-pagination .swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  margin: 0 0.25rem !important;
  background: var(--main__color) !important;
  opacity: 0.8;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.swiper-pagination .swiper-pagination-bullet-active {
  width: 15px;
  height: 15px;
  background: #fff !important;
  border: 3px solid #fff;
}

.swiper-button-next,
.swiper-button-prev {
  color: var(--main__color) !important;
}

.swiper-button-prev,
.swiper-rtl .swiper-button-next {
  left: 25px;
}

.swiper-button-next,
.swiper-rtl .swiper-button-prev {
  right: 25px;
}
