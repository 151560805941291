/******* Bannar Styles
*************************/
.cta__wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #41246c;
  min-height: 394px;
  margin: 0 auto;
  color: var(--white);
  text-align: center;
  /* padding-top: 130px; */
  position: relative;
}
.cta__title {
  margin-bottom: 0;
  font-size: 60px;
  font-weight: 900;
}

.cta__text {
  width: 680px;
  margin: 0 auto;
  font-size: 18px;
  line-height: 27px;
  font-weight: normal;
  margin-top: 10px;
  color: rgb(206, 214, 214);
}

.cta__btn {
  font-size: 16px;
  padding: 13px 25px;
  line-height: 20px;
  margin-top: 50px;
  background-color: transparent;
  border: 1px solid var(--white) !important;
  color: var(--white) !important;
  /* box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px; */
  box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
  transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
}
.cta__btn:hover {
  color: var(--white) !important;
  background-color: var(--bg__color) !important;
  border-color: var(--bg__color) !important;
  transform: scale(1.1);
}

@media (max-width: 768px) {
  .cta__title {
    font-size: 20px;
  }
  .cta__text {
    width: 100%;
  }

  .cta__btn {
    font-size: 14px;
    padding: 5px 10px;
  }
}
