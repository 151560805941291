/* sliders */
.testi__slides {
  min-height: 250px;
}
.testi__slides .swiper-wrapper {
  height: 250px;
}
.testi__slides .swiper-wrapper .swiper-slide {
  display: flex;
  align-items: center;
}
.testi__slides .swiper-button-prev {
  right: 0;
  bottom: 55px !important;
  background-color: var(--bg__dark_color);
}
.testi__slides .swiper-button-next {
  right: 0;
  bottom: 0;
  background-color: var(--main__color);
}
.testi__slides .swiper-button-prev,
.testi__slides .swiper-button-next {
  height: 55px;
  width: 55px;
  display: flex;
  align-items: center;
}

.swiper-button-next,
.swiper-button-prev {
  top: unset !important;
  left: auto !important;
}

.testi__slides .swiper-button-prev::after,
.testi__slides .swiper-button-next::after {
  color: var(--white);
  font-size: 22px;
}
